







































import { Component, Vue } from 'vue-property-decorator'
import caseGrid from '@/components/case/pass.vue'
import { Getter, Action } from 'vuex-class'
import utils from '@/utils/util.ts'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    caseGrid
  },
  mixins: [globalMixins]
})
export default class ATSViewsCaseCheck extends Vue<globalMixins> {
  @Getter('total') total: number
  @Getter('items') items: Object[]
  @Getter('fields') fields: any[]
  @Action('caseList') caseList: Function
  @Action('caseReviewBatch') caseReviewBatch: Function

  loading: boolean = false
  case_ids: any[] = []
  pagination: any = {
    page: 1,
    size: 10
  }
  query: any = {
    status: 'pending',
    keyword: ''
  }
  project = JSON.parse(localStorage.getItem("projects"))
  value: string = ''
  casePending: Object[] = []
  caseRejected: Object[] = []

  get rejectOrPass () {
    return this.project.role === 'professor' || this.project.role === 'leader'
  }
  mounted () {
    this.query['projectId'] = this.project.id
    if (this.$route.query !== {} && this.$route.query.status) {
      this.query.status = this.$route.query.status
    }
    this.doSearch()
  }
  // 转为驳回
  turnToReject () {
    if (this.case_ids.length === 0) {
      this.errorMsg('请选择数据进行操作!')
      return
    }
    this.$prompt('请输入驳回原因(必填)', {
     confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /\S/,
      inputErrorMessage: '驳回原因为必填'
    }).then(value => {
      let params = {
        case_ids: this.case_ids,
        operation: 'reject',
        remark: value['value'],
        projectId: this.project.id
      }
      this.caseReviewBatch({ params }).then(() => {
        this.successMsg('已成功驳回!')
        this.doSearch()
      })
      .catch(() => {
        this.errorMsg('批量审核失败, 请稍后重试!')     
      })
    }).catch((err) => {
      this.errorMsg(err)
    })
  }
  handleSelectionChange (ids) {
    this.case_ids = ids
  }
  // 审核通过
  turnToApprove () {
    if (this.case_ids.length === 0) {
      this.errorMsg('请选择数据进行操作!')
      return
    }
    let params = {
      operation: 'approve',
      case_ids: this.case_ids,
      projectId: this.project.id
    }
    this.caseReviewBatch({ params }).then(() => {
      this.successMsg('批量审核操作成功!')
      this.doSearch()
    }).catch(() => {
      this.errorMsg('批量审核失败, 请稍后重试!')
    })
  }
  doKeywordSearch () {
    this.pagination.page = 1
    this.doSearch()
  }
  onPagerSizeChange (val: string) {
    this.pagination.size = val
    this.doSearch()
  }
  onPagerCurrentChange (val: string) {
    this.pagination.page = val
    this.doSearch()
  }
  doSearch () {
    this.loading = true
    let pagination = utils.pager(this.pagination.page, this.pagination.size)
    let params = Object.assign({}, this.query, {limit: pagination.limit}, {offset: pagination.offset}) 
    if (this.query.keyword === '') {
      delete params.keyword
    }
    this.caseList({ params }).then(() => {
      setTimeout(() => {
        this.loading = false
      }, 200)
    })
  }
  handleStatusChange(tab, event) {
    this.query.status = tab.name
    this.doSearch()
  }
}
