



































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    items: Array,
    fields: Array
  }
})
export default class PassGrid extends Vue {
  case_ids: any = []
  
  handleSelectionChange(val) {
    this.case_ids = []
    val.map((v: {id: number}) => {
      this.case_ids.push(v.id)
    })
    this.$emit('handleSelectionChange', this.case_ids)
  }
}
